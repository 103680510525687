import { createStore } from 'vuex';
import { postPaas } from "../assets/utils/request"
import { copyData } from "../assets/utils/copy"
import {getDateByTimestamp, getTimestampByDate} from "@/utils/time-zone";
import {postDataToApp} from "@/utils/jsBridge";
import {releaseHoldVoucher} from "../../plugin/coupon";

const DEFAULT_LOCATION = {lat:22.3192,lng:114.1693}

export default createStore({
  state: {
    defaultLocation: {...DEFAULT_LOCATION},
    loginGet:1,
    topBannerFirst:null,
    tabbarShow: true,
    language: 1,
    userInfo: null,
    yuuToken:null,
    yuuTokenlogin:null,
    sendType: null,
    cart: [],//购物车
    updateProIndex: null,//修改的购物车中的餐品的下标
    placeInfo: null,
    position: {},//app传过来的经纬度
    pageTypeNum: 1,
    location: null,//定位经纬度
    locationName: "",//定位地区的名称
    time: null,//H01 早餐 H02 午餐 H03 下午茶 H04 晚餐
    userTitle: null,
    addressLits:[],
    addressTag: null,//地址标签
    menuType: null,//当钱的餐单业务类型
    totalPrice: 0,//购物车总价钱
    addPlaceInfo: {},//添加地址時的地址信息
    deviceId: null,
    hashTag: null,
    money: ["$", "$"],
    moneyMark: ["HKD", "MOP"],
    phone: [852, 853],
    area: 0,
    app: false,
    isYuu: false,
    routers: ["OrderFood"],
    transition: "slide-left",
    clearCou: true,//是否要清除優惠券信息
    setNotice: false,
    user_longlat: null,//用户经纬度
    showCoupon: false,
    jump: true,
    showCart: false,
    reserveDate: null,
    yuuInfo: null,
    yuuLoginInfo: null,
    isPC: false,
    couponInfo: null,//优惠券信息
    couponInfos: null,//选择优惠券信息，未加入购物车时
    saveCouponInfos:null,//加入购物车的优惠券信息
    couponProducts:[],//优惠券餐品
    appInfo: null,
    H5Seting: null,
    isBackOrderFood: false,//是否是从确认订单页返回并且打开预约时间 弹窗
    backStoreId: null,//从确认订单页面返回时的门店id
    activeInfo:null,
    showSendCartData:{},
    utmData:'',//从链接上获取的参数名带‘utm_’的参数
    yuuLoginData:{},
    isShowLoading:true, //是否显示Loading
    youCurrenPosition:{...DEFAULT_LOCATION,isNoGps:true},
    geolocationStatus: 0, // -1 正在获取定位 0 未获取定位 1 定位成功  2 定位失败
    advertisingList:[], // 弹屏
    isShowAdvert: true,
    isReturnMap:true,
    isShowAdvertModal:false,
    getStorePres:[],//门店支持活动
    yuuCouponList:[],//缓存yuu优惠券列表
    registerData:null,
    confirmorderData:{},//代替确认订单页url上的参数缓存数据
    foodDetailData:{},//代替餐品详情url上的参数缓存数据
    payToConfirmData:{},//代替支付失败回到确认订单页url上的参数缓存数据
    couponDetailData:{},//积分商城跳转到积分详情参数
    urlData:{},
    themeGetConfig:{},
    priceLoading:false,
    gloveProductObj:{},
    evenAddCartData:null,//是否触发GA加入购物车事件,有值则触发
  },
  getters: {
    getTabbarShow(state) {
      return state.tabbarShow
    },
    // 获取 默认定位或者 默认地址【旺角】经纬度
    getCurrenPosition (state) {
      const {youCurrenPosition = {}, defaultLocation} = state
      const data = {...youCurrenPosition}
      if (!data.lat) {
        data.lat = defaultLocation.lat
        data.lng = defaultLocation.lng
      }
      return data
    }
  },
  mutations: {
    setGloveProduct(state, payload){
      state.gloveProductObj = payload
    },
    evenAddCart(state, payload){
      state.evenAddCartData = payload
    },
    setThemeGetConfig(state, payload){
      state.themeGetConfig = payload
    },
    setUrlData(state, payload){
      if(payload.pageKey){
        if(state[payload.pageKey]){
          state[payload.pageKey] = {};
          state[payload.pageKey] = payload
        }else{
          state.urlData[payload.pageKey] = {};
          state.urlData[payload.pageKey] = payload;
        }
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    setRegisterData(state, payload){
      state.registerData = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 是否返回详情页返回
    saveIsShowAdvertModal(state, payload){
      state.isShowAdvertModal = payload
    },
    // 是否返回地图页
    saveIsReturnMap(state, payload){
      state.isReturnMap = payload
    },
    // 是否显示弹屏
    saveIsShowAdvert(state, payload){
      state.isShowAdvert = payload
    },
    // 弹屏
    saveAdvertisingList(state, payload){
      state.advertisingList = payload
    },
    saveTopBanner(state, payload){
      state.topBannerFirst = payload
    },
    saveYuuList(state, payload){
      state.yuuCouponList = payload||[]
    },
    //是否显示Loading
    saveIsShowLoading(state, payload) {
      state.isShowLoading = payload
    },
    // h5链接跳转过来带‘utm_’的参数
    saveUtmInfo(state, payload) {
      state.utmData = payload
    },
    // 保存从确认订单页面返回时的门店id
    saveBackStoreId(state, payload) {
      state.backStoreId = payload
    },
    // 是否是从确认订单页返回并且打开预约时间 弹窗
    saveIsBackOrderFood(state, payload) {
      state.isBackOrderFood = payload;
    },
    // 
    saveH5Seting(state, payload) {
      state.H5Seting = payload
    },
    // app跳过来的信息
    saveAppInfo(state, payload) {
      state.appInfo = payload
    },
    resetCouponInfo(state){
      state.couponInfos = [];
      state.couponProducts = [];
    },
    // 优惠券信息
    saveCouponInfo(state, payload) {
      if (payload) {
        if (payload.voucherType == 1 || payload.voucherType == 4) {
          payload.showPrice = payload.voucherAmount / 100
        } else if (payload.voucherType == 2) {
          payload.showPrice = payload.showPrice || 0
        } else {
          payload.showPrice = payload.canDaoFixPrice ? payload.canDaoFixPrice / 100 : 0
        }
      }
      state.couponInfo = payload;
      localStorage.setItem("store", JSON.stringify(state))
    },

    //-----多张券-------
    // 添加对应券
    selectCouponInfos(state, payload){
      let couponInfo = JSON.parse(JSON.stringify(payload))
      if (couponInfo) {
        if (couponInfo.voucherType == 1 || couponInfo.voucherType == 4) {
          couponInfo.showPrice = couponInfo.voucherAmount / 100
        } else if (couponInfo.voucherType == 2) {
          couponInfo.showPrice = couponInfo.showPrice || 0
        } else {
          couponInfo.showPrice = couponInfo.canDaoFixPrice ? couponInfo.canDaoFixPrice / 100 : 0
        }
      }
      // state.couponInfo = payload;
      if(state.couponInfos){
        let isHaveCoupon = state.couponInfos.some(item=>item?.voucherCode==state.couponInfos?.voucherCode);
        if(!isHaveCoupon){
          state.couponInfos.push(couponInfo)
        }
      }else{
        state.couponInfos = [couponInfo]
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 当前页面所选券回选
    initCouponInfos(state, payload){
      state.couponInfos = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 当前页面优惠餐品回选
    initCouponPro(state, payload){
      state.couponProducts = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    upDataCouponInfos(state, payload){
      state.saveCouponInfos = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 所选优惠券加入购物车
    saveCouponInfos(state, payload){
      let couponList = payload;
      if(couponList){
        // 现金券按从大到小排序
       couponList.sort((a,b)=>{
          if(a.voucherType==1&&b.voucherType==1){
            return b.voucherAmount-a.voucherAmount
          }else if(b.voucherType==1&&a.voucherType!=1){
            return -1
          }else{
            return true;
          }
        })
      }
      // 增加去重
      let newList = [],codelist = [];
      couponList.forEach(m=>{
        if(codelist.indexOf(m.voucherCode)<0){
          codelist.push(m.voucherCode);
          newList.push(m);
        }
      })
      state.saveCouponInfos = newList;
      // state.saveCouponInfos = payload;
      localStorage.setItem("store", JSON.stringify(state))
    },
    addCartCoupon(state,payload){
      let couponInfo = JSON.parse(JSON.stringify(payload))
      if (couponInfo) {
        if (couponInfo.voucherType == 1 || couponInfo.voucherType == 4) {
          couponInfo.showPrice = couponInfo.voucherAmount / 100
        } else if (couponInfo.voucherType == 2) {
          couponInfo.showPrice = couponInfo.showPrice || 0
        } else {
          couponInfo.showPrice = couponInfo.canDaoFixPrice ? couponInfo.canDaoFixPrice / 100 : 0
        }
      }
      let saveCouponInfos =state.saveCouponInfos||[];
      let isHave = saveCouponInfos.some(m=>m.voucherCode==payload.voucherCode);
      if(!isHave){
        if(state.saveCouponInfos){
          state.saveCouponInfos.push(couponInfo)
        }else{
          state.saveCouponInfos=[couponInfo]
        }
      }
      if(state.saveCouponInfos){
        // 现金券按从大到小排序
        state.saveCouponInfos.sort((a,b)=>{
          if(a.voucherType==1&&b.voucherType==1){
            return b.voucherAmount-a.voucherAmount
          }else if(b.voucherType==1&&a.voucherType!=1){
            return -1
          }else{
            return true;
          }
        })
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 选择多张优惠券优惠券餐品
    selectCouponProducts(state, payload){
      if(payload){
        let isHavePro = state.couponProducts.some(item=>item?.product?.pid==payload?.product?.pid&&item?.product?.voucherCode==payload?.product?.voucherCode);
        if(!isHavePro){
          state.couponProducts.push(payload)
        }
      }
      localStorage.setItem("store", JSON.stringify(state));
    },
    // 删除对应券
    deleCouponInfos(state, payload){
      if( state.couponInfos){
        let couponInfos = state.couponInfos.filter(m=>m.voucherCode!=payload.voucherCode).map(m=>m);
        state.couponInfos = couponInfos;
      }
    
    },
    // 清空优惠券
    clearCouponInfo(state, payload){
      // 如果为mezzofy，移除前需要调解锁接口
      if(state.saveCouponInfos){
        let mezzofyCodeList = (state.saveCouponInfos||[]).filter(item=>item.isMezzofy).map(m=>m.voucherCode)
        releaseHoldVoucher(mezzofyCodeList)
      }
      state.couponInfos = payload||null;//弹窗所保存券
      state.couponProducts = null;//弹窗所存餐品
      state.saveCouponInfos = null;//购物车所存券
      state.cart.forEach((item,index)=>{
        if(item.isCouponPro){
          state.cart.splice(index,1)
        }
      })
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 移除购物车某张优惠券
    clearCouponOne(state, payload){
      if(!state.saveCouponInfos){
        return;
      }
      let newListCoupon = [];
      // 清除购物车券信息
      newListCoupon = (state.saveCouponInfos||[]).filter(m=>m.voucherCode!=payload?.voucherCode).map(m=>m)
      state.saveCouponInfos = newListCoupon;
      // 餐品券清除购物车餐品
      state.cart.forEach((item,index)=>{
        if(item.isCouponPro&&item.voucherCode==payload.voucherCode){
          state.cart.splice(index,1)
        }
      })
      // 如果移除的为mezzofy券，则需要解锁,如果不需要解锁，则传isNotRemove=true
      if(payload?.isMezzofy&&!payload?.isNotRemove){
        releaseHoldVoucher([payload.voucherCode])
      }
      this.commit("deleCouponInfos", payload);
      localStorage.setItem("store", JSON.stringify(state))
    },
    // -------多张券-----


    // 是否是PC端
    saveIsPC(state, payload) {
      state.isPC = payload

    },
    saveUpdateProIndex(state, payload) {
      state.updateProIndex = payload
    },
    saveYuuInfo(state, payload) {
      state.yuuInfo = payload
    },
    saveYuuLoginInfo(state, payload) {
      state.yuuLoginInfo = payload
    },
    // 是否是yuu跳转进来
    saveYuu(state, payload) {
      state.isYuu = payload
    },
    // 预约时间
    saveReserveDate(state, payload) {
      state.reserveDate = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 是否顯示購物車
    changeShowCart(state, payload) {
      state.showCart = payload
    },
    //是否展示满送餐品弹窗
    changeShowSendCart (state, payload) {
      state.showSendCartData = payload
    },
    updateTabbarShow(state, payload) {
      state.tabbarShow = payload
    },
    setUserInfo(state, payload) {
      try {
        payload.accessCode = payload.accessCode || (state?.userInfo?.accessCode || '')
      } catch (err) {
        console.error(err)
      }
      state.userInfo = payload
      if(!state.userInfo||!state.userInfo.yuuId){
        state.yuuTokenlogin = null
      }
    },
    setYuuyuuId(state, payload){
      if(state.userInfo){
        state.userInfo.yuuId = payload.yuuId||'';
      }
      if(!state.userInfo||!state.userInfo.yuuId){
        state.yuuTokenlogin = null
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    setYuuyuuToken(state, payload){
      // 当不是yuu,并且会员模式时，登录yuu后获取到yuuToken保存来判断yuu是否登录
      if(!state.isYuu){
        if(state.userInfo){
          state.yuuTokenlogin = payload
        }else{
          state.yuuTokenlogin = null
        }
      }
    
    },
    saveYuuLoginData(state, payload){
      state.yuuLoginData =  payload;
    },
    // 清除個人信息
    clearUserInfo(state) {
      state.userInfo = null
      state.yuuTokenlogin = null
    },
    // 修改语言
    setLanguage(state, payload) {
      state.language = payload;
      this.dispatch("getHashtag")
      this.dispatch("getTitle")
    },
    // 修改购物车折扣餐品价格
    editCartProductDisPrice(state, payload) {
      if (!payload.uids) return;
      if (!state.cart || !state.cart.length) return
      for (var i = 0; i < state.cart.length; i++) {
        if (payload.uids.indexOf(state.cart[i].uid) > -1) {
          state.cart[i].price = (payload.proPrice[state.cart[i].uid].productDisplayPrice * 100 + payload.proPrice[state.cart[i].uid].price * 100) / 100
        }
      }
    },
    //创建购物车
    createCart(state, payload) {
      if (payload.toString() == "{}" || payload.toString() == "") return;
      if (payload.update) {
        // 非满送餐品
        if (!payload.product.isSendType&&payload.product.type == 1&&payload.product?.listRequirements.length) {
          // 更新单品带特殊要求的餐品时，如果判断到存在一样特殊要求的餐品，则合并
          let hasOldProSingle = false;
          for (let i = 0; i < state.cart.length; i++) {
            if (state.updateProIndex!=i&&state.cart[i].uid == payload.product.uid && !state.cart[i].isCouponPro) {
              let singleOldListRequirements = state.cart[i].listRequirements;
              if (singleOldListRequirements && singleOldListRequirements.length != 0) {
                let singleOldPidJson = JSON.stringify(singleOldListRequirements[0].pidData);
                let singleNewPidJson = JSON.stringify(payload.product.listRequirements[0].pidData)
                // 存在相同特殊要求的餐品
                if (singleOldPidJson == singleNewPidJson) {
                  hasOldProSingle = true
                  state.cart[i].num++;
                }
              }
            }
          }
          if(!hasOldProSingle){
            state.cart[state.updateProIndex] = payload.product
          }else{
            state.cart.splice(state.updateProIndex,1);
          }
        }else{
          state.cart[state.updateProIndex] = payload.product
        }

        return
      }
      var hasProduct = false;
      var productIndex = [];

      if (payload?.product?.isCouponPro) {
        let isAddCar = -1;//是否已经加入过购物车
        state.cart.forEach((item, index) => {
          // if (item.isCouponPro) {
          //   state.cart.splice(index, 1)
          //   return
          // }
          // 多张优惠券问题
          if(item.voucherCode&&item.voucherCode==payload.product?.voucherCode){
            isAddCar = index ;
            // state.cart.splice(index, 1)
          }
        })
        if (payload.product.num != 0) {
          if(isAddCar==-1){
            state.cart.push(payload.product);
          }else{
            state.cart[isAddCar]=payload.product;
          }
          localStorage.setItem("store", JSON.stringify(state))
        } else {
          localStorage.setItem("store", JSON.stringify(state))
        }
        return
      }
      // 满送
      if(payload.product.isSendType&&payload.product.isSendType==1){
        if (!payload.noDel) {
          state.cart.forEach((item, index) => {
            if (item.isSendType) {
                state.cart.splice(index, 1)
                // return
              }
          })
        }

        if (payload.product.num != 0) {
          state.cart.push(payload.product)
          localStorage.setItem("store", JSON.stringify(state))
        } else {
          localStorage.setItem("store", JSON.stringify(state))
        }
        return
      }
      // *
      for (var i = 0; i < state.cart.length; i++) {
        if (state.cart[i].uid == payload.product.uid && !state.cart[i].isCouponPro) {
          var hasoldProArr = [];
          if (payload.product.type == 1) {
            var hasOldProSingle = false;
            if (!state.cart[i].isSendType) {
              var singleOldListRequirements = state.cart[i].listRequirements;
              if (singleOldListRequirements && singleOldListRequirements.length != 0) {
                var singleOldPidJson = JSON.stringify(singleOldListRequirements[0].pidData);
                var singleNewPidJson = JSON.stringify(payload.product.listRequirements[0].pidData)
                if (singleOldPidJson == singleNewPidJson) {
                  hasOldProSingle = true
                }
              } else {
                hasOldProSingle = true
              }
            }
            hasoldProArr.push(hasOldProSingle)
          } else {
            var oldG = copyData(state.cart[i].selectedFood);
            var newG = copyData(payload.product.selectedFood);
            // 所选套餐子餐品的数量
            let oldProLen = 0;
            let newProLen1 = 0;
            for (var o = 0; o < oldG.groups.length; o++) {
              for (var oM = 0; oM < oldG.groups[o].mains.length; oM++) {
                for (var oMG = 0; oMG < oldG.groups[o].mains[oM].groups.length; oMG++) {
                  var OP = oldG.groups[o].mains[oM].groups[oMG].products
                  oldProLen+=OP.length
                  for (var oMGP = 0; oMGP < OP.length; oMGP++) {

                    var hasOldPro = false;
                    newProLen1 = 0;
                    for (var n = 0; n < newG.groups.length; n++) {
                      for (var nM = 0; nM < newG.groups[n].mains.length; nM++) {
                        for (var nMG = 0; nMG < newG.groups[n].mains[nM].groups.length; nMG++) {
                          var NP = newG.groups[n].mains[nM].groups[nMG].products;
                          newProLen1 += NP.length
                          for (var nMGP = 0; nMGP < NP.length; nMGP++) {
                            if (OP[oMGP].pid == NP[nMGP].pid && OP[oMGP].num == NP[nMGP].num) {
                              if (OP[oMGP].listPropertys && OP[oMGP].listPropertys.length > 0) {
                                var oPidJsonObj = {};
                                var nPidJsonObj = {};
                                var hadOldPro = true;
                                for (var ol = 0; ol < OP[oMGP].listPropertys.length; ol++) {
                                  var oPidJson = JSON.stringify(OP[oMGP].listPropertys[ol].pidData);
                                  if (oPidJsonObj[oPidJson]) {
                                    oPidJsonObj[oPidJson] += OP[oMGP].listPropertys[ol].num
                                  } else {
                                    oPidJsonObj[oPidJson] = OP[oMGP].listPropertys[ol].num
                                  }
                                }
                                for (var nl = 0; nl < NP[nMGP].listPropertys.length; nl++) {
                                  var nPidJson = JSON.stringify(NP[nMGP].listPropertys[nl].pidData);
                                  if (nPidJsonObj[nPidJson]) {
                                    nPidJsonObj[nPidJson] += NP[nMGP].listPropertys[nl].num
                                  } else {
                                    nPidJsonObj[nPidJson] = NP[nMGP].listPropertys[nl].num
                                  }
                                }
                                for (var key in oPidJsonObj) {
                                  if (!nPidJsonObj[key] || nPidJsonObj[key] != oPidJsonObj[key]) {
                                    hadOldPro = false;
                                    break
                                  }
                                }
                                hasOldPro = hadOldPro;
                                
                              } else {
                                hasOldPro = true
                              }
                            }
                          }
                        }
                      }
                    }
                    
                    hasoldProArr.push(hasOldPro)
                  }
                }
              }
            }
            // bug41935 处理判断当新加入购物车的餐品所选餐品其他板块都一样，仅新加餐品多了可选加料多情况，如果没有数量判断，则会被判断为相同餐品合并
            if(newProLen1&&oldProLen&&newProLen1>oldProLen){
              hasoldProArr.push(false)
            }
          }
         
          if (hasoldProArr.indexOf(false) < 0) {
            hasProduct = true;
            productIndex.push(i)
            break
          }
        }
      }
      console.log(hasProduct,'hasProduct')
      if (hasProduct) {
        var hasOnce = false;
        for (var k = 0; k < productIndex.length; k++) {
          if (payload.product.type == 1) {
            // var newProduct = payload.product.listRequirements
            // var newProductJson = JSON.stringify(newProduct)
            // var oldProduct = state.cart[productIndex[k]].listRequirements
            // var oldProductJson = JSON.stringify(oldProduct)
            // if (newProductJson == oldProductJson) {
            if (payload.product.num == 0) {

              state.cart.splice(productIndex[k], 1)
              localStorage.setItem("store", JSON.stringify(state))
              return;
            }
            var oldNum = Number(state.cart[productIndex[k]].num)
            var newNum = Number(payload.product.num)
            if (payload.cart) {
              state.cart[productIndex[k]].num = newNum
            } else {
              state.cart[productIndex[k]].num = newNum + oldNum
            }
            hasOnce = true
            break
            // }
          } else {
            // var newPro = payload.product
            // var newProJson = JSON.stringify(newPro.selectedFood)

            // var oldPro = state.cart[productIndex[k]]
            // var oldProJson = JSON.stringify(oldPro.selectedFood)

            // if (newProJson == oldProJson) {
            if (payload.product.num == 0) {
              state.cart.splice(productIndex[k], 1)
              localStorage.setItem("store", JSON.stringify(state))
              return;
            }
            var newProNum = Number(payload.product.num)
            var oldProNum = Number(state.cart[productIndex[k]].num)

            if (payload.cart) {
              state.cart[productIndex[k]].num = newProNum
            } else {
              state.cart[productIndex[k]].num = newProNum + oldProNum
            }
            hasOnce = true
            break
            // }
          }
        }
        if (!hasOnce) {
          state.cart.push(payload.product)
        }
      } else {
        if (payload.product.num != 0) {
          state.cart.push(payload.product)
        }
      }
      console.log(state.cart,'state.cart')
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 清空购物车
    clearCart(state) {
      state.cart = []
      state.showSendCartData = {};
      this.commit("clearCouponInfo", null);
      localStorage.setItem("store", JSON.stringify(state))
    },
     // 清空满送餐品
    clearCartSendPro(state) {
        for (let i = state.cart.length - 1; i > -1; i--) {
            if(state.cart[i].isSendType){
                state.cart.splice(i, 1)
            }
        }
      localStorage.setItem("store", JSON.stringify(state))
    },
    setActiveInfo(state,payload) {
      if (payload) {
        state.activeInfo = payload||null;
      } else {
        state.activeInfo = null;
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 完整替换购物车
    replaceCart(state, payload) {
      state.cart = payload
      let list = []
      state.cart.forEach(m=>{
        if(m.isSendType){
          list.push(m)
        }
      })
      if(list.length==state.cart.length){
        state.cart = [];
        localStorage.setItem("store", JSON.stringify(state))
      }
    },
    // 购物车总价钱
    setTotalPrice(state, payload) {
      state.totalPrice = payload
    },
    // 保存地址信息
    savePlace(state, payload) {
      let isOrderDetail = window.location.pathname.indexOf('orderDetail')>-1|| window.location.hash.indexOf('orderDetail')>-1
      if(!payload
        ||!state.placeInfo
        ||payload.lat!=state.placeInfo.lat
        ||payload.lng!=state.placeInfo.lng
        ||payload?.addressCode!=state.placeInfo?.addressCode){
          if(!isOrderDetail){
             // 地址信息变更,如果为app 环境，则需通知app
            try{
              postDataToApp('setAddress',payload||{});
            }catch(err){
              console.error(err)
            }
          }
      }
      state.placeInfo = payload;
      (payload&&payload?.page)&&(delete state.placeInfo.page);
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 退出登陆
    logout(state) {
      state.userInfo = null
      state.yuuTokenlogin = null;
    },
    // 保存app传过来的位置信息
    savePosition(state, payload) {
      state.position = payload
    },
    // 改变tab页面
    changeTabPage(state, payload) {
      state.pageTypeNum = payload
    },
    // 定位的经纬度
    saveLocation(state, payload) {
      state.location = payload
    },
    // 定位的地区名称
    saveLocationName(state, payload) {
      state.locationName = payload
    },
    // 餐品时间
    saveTime(state, payload) {
      state.time = payload
    },
    // 选取优惠券 保存餐品时间
    // 与公共方法 setHashTag 功能重复？
    saveTimeSelectCoupon(state, SERVER_TIME) {
      // var nowTime = new Date().getTime(); //当前时间
      // var year = new Date().getFullYear();
      // var month = new Date().getMonth() + 1;
      // var day = new Date().getDate();

      var nowTime = SERVER_TIME; //当前时间
      let { year, month, day } = getDateByTimestamp(SERVER_TIME)
      var date = year + "/" + month + "/" + day + " ";
      // var breakfast = new Date(date + "10:59").getTime();
      // var lunch = new Date(date + "14:29").getTime();
      // var tea = new Date(date + "17:29").getTime();

      var breakfast = getTimestampByDate(date + "10:59")
      var lunch = getTimestampByDate(date + "14:29")
      var tea = getTimestampByDate(date + "17:29")

      var saveTimeType;
      if (nowTime < breakfast) {
        saveTimeType = "H01";
      } else if (nowTime < lunch) {
        saveTimeType = "H02";
      } else if (nowTime < tea) {
        saveTimeType = "H03";
      } else {
        saveTimeType = "H04";
      }
      state.time = saveTimeType
    },
    //保存用户title标签
    saveUserTitle(state, payload) {
      state.userTitle = payload
    },
    saveAddressList(state, payload){
      state.addressLits = payload||[];
    },
    // 保存地址标签
    savePlaceTag(state, payload) {
      state.addressTag = payload
    },
    // 保存当前的餐单业务类型
    saveMenuType(state, payload) {
      state.menuType = payload
    },
    save(state, payload) {
      state = payload
    },
    // 保存hashTag
    saveHashtag(state, payload) {
      state.hashTag = payload
    },
    // 保存地區
    saveArea(state, payload) {
      state.area = payload
    },
    // 保存是否是app
    saveApp(state, payload) {
      state.app = payload
    },
    // 保存添加地址是的數據
    saveAddPlaceInfo(state, payload) {
      state.addPlaceInfo = payload
    },
    // 清除添加時的數據
    clearAddPlaceInfo(state) {
      state.addPlaceInfo = null
    },
    // h还原vuex数据
    reloadVuex(state) {
      state.tabbarShow = true
      state.userInfo = null
      state.sendType = null
      state.cart = []//
      state.placeInfo = null
      state.position = {}//app传过来的经纬度
      state.pageTypeNum = 1
      state.location = null//定位经纬度
      state.locationName = ""//定位地区的名称
      state.time = null//H01 早餐 H02 午餐 H03 下午茶 H04 晚餐
      state.menuType = null//当钱的餐单业务类型
      state.totalPrice = 0//购物车总价钱
      state.addPlaceInfo = null//添加地址時的地址信息
      state.app = false
      state.deviceId = null
      state.yuuTokenlogin = null
    },
    clearRouter(state) {
      state.routers = []
    },
    saveRouter(state, payload) {
      state.routers = [payload]
    },
    saveRouters(state, payload) {
      state.routers = payload
    },
    // 保存传过来的机型
    saveDeviceId(state, payload) {
      state.deviceId = payload
    },
    chnageTransion(state, payload) {
      state.transition = payload
    },
    changeClearCou(state, payload) {
      state.clearCou = payload
    },
    chageSetNotice(state, payload) {
      state.setNotice = payload
    },
    // 用户经纬度
    saveUserPlace(state, payload) {
      state.user_longlat = payload
    },
    // 是否弹出优惠券
    saveShowCoupon(state, payload) {
      state.showCoupon = payload
    },
    saveJumpDetail(state, payload) {
      state.jump = payload
    },
    // 修改用戶信息
    updateUserInfo(state, payload) {
      for (var key in payload) {
        state.userInfo[key] = payload[key]
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    saveStorePres(state, payload){
      state.getStorePres = payload
    },
    saveCurrenPosition(state, payload){
      // isNotOpenGps-是否没有开启gps导致的没有定位
      // isNoGps-是否使用的默认定位
      if (!payload||!payload.lat) {
        state.youCurrenPosition = {lat:22.3192,lng:114.1693,isNoGps:true,isNotOpenGps:payload?.isNotOpenGps||true}
        return
      }
      state.youCurrenPosition = {
        ...payload,
        location: true
      }

    },
    resetGeolocationStatus(state) {
      state.geolocationStatus = 0
      sessionStorage.setItem('isGetGeolocation', '0')
    },
    saveGeolocationStatus(state, payload) {
      state.geolocationStatus = payload
      const isGetGeolocation = parseInt(sessionStorage.getItem('isGetGeolocation') || '0', 10)

      if ([1, 2].includes(payload) && !isGetGeolocation) {
        sessionStorage.setItem('isGetGeolocation', '1')
      }

      // console.log('state.geolocationStatus:',state.geolocationStatus)
      // console.log('isGetGeolocation:', sessionStorage.getItem('isGetGeolocation'))
    },
    // 餐单页购物车价格loading控制
    setPriceLoading(state, payload){
      state.priceLoading = payload
    }
    
  },
  actions: {
    async getTitle() {
      let params = {
        actionName: "candao.member.miscUserTitles",
        content: {},
      };

      let placeParams = {
        actionName: "candao.member.miscAddressTag",
        content: {},
      };
      if (this.state.language == 1) {
        params.content.language = "TC";
        placeParams.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result && result.status == 1) {
        // result.data.data.splice(result.data.data.length - 1, 1);
        let list = result.data.data;
         list.forEach(m=>{
          if(m.titlesCode=='U04'){
            m.titlesName = params.content.language == "TC"?'非公開':'Prefer not to say'
          }
         })
        this.commit("saveUserTitle", list);
      }
      let placeResult = await postPaas("UserUnify", placeParams);
      for (var i = 0; i < placeResult.data.data.length; i++) {
        if (placeResult.data.data[i].tagId == 1) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "屋企" : "Home"
        } else if (placeResult.data.data[i].tagId == 2) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "公司" : "Office"
        } else if (placeResult.data.data[i].tagId == 3) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "其他" : "Other"
        }
      }
      this.commit("savePlaceTag", placeResult.data.data);
    },
    async getHashtag() {
      let params = {
        actionName: "candao.member.miscHashTag",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.commit("saveHashtag", result.data.data);
      }
    },
  },
  modules: {
  }
})
