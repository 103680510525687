<template>
  <div id="app" class="app" :class="$store.state.language == 2?'en':''" @dblclick="doubleClick">
    <router-view v-slot="{ Component }">
      <transition name="">
        <div>
          <keep-alive :include="$store.state.routers">
            <component :is="Component" />
          </keep-alive>
        </div>
      </transition>
    </router-view>
    <div class="bar">
      <Tabbar v-if="tabbarShow" :loading="loading"></Tabbar>
    </div>
    <div v-show="$store.state.setNotice">
      <set-notice
        :leftBtn="leftBtn"
        :rightBtn="rightBtn"
        :title="title"
        :content="content"
        @leftEvent="leftEvent"
        @rightEvent="rightEvent"
      ></set-notice>
    </div>
    <img v-if="isPCImg" class="isPCImg isPCImgLeft" :src="isPCImgUrlLeft" alt="" srcset="">
    <img v-if="isPCImg" class="isPCImg isPCImgRight" :src="isPCImgUrlRight" alt="" srcset="">
	<div v-if="$store.state.language == 1">
		<food-confirm v-show="showYuuLogin" :type="1" :title="language.USERYUU.chinese" @cancle="backPage" :rightBtn="language.confirm.chinese"></food-confirm>
	</div>
	<div v-else>
		<food-confirm v-show="showYuuLogin" :type="1" :title="language.USERYUU.chinese" @cancle="backPage" :rightBtn="language.confirm.english"></food-confirm>
	</div>
  <div v-if="showMaptip">
      <!-- leftBtn="稍后" -->
		<food-confirm 
      v-show="showMaptip" 
      :title="$store.state.language == 1?language.locationTip.chinese:language.locationTip.english" 
      @cancle="closeTip" 
      @confirm="closeTip" 
      :type="1"
      :rightBtn="$store.state.language == 1?language.confirm.chinese:language.confirm.english "></food-confirm>
	</div>
  </div>
</template>

<script>
import Tabbar from "./components/Tabbar.vue";
import SetNotice from "./components/common/SetNotice.vue";
// import braze from "@braze/web-sdk";
import * as braze from "@braze/web-sdk";
import "./assets/js/language.js";
import language from "./assets/js/language.js";
import { postPlace, getPlace, postPaas, postDC ,postLogin } from "./assets/utils/request";
import { mapMutations } from "vuex";
import FoodConfirm from "./components/food/OrderConfirm.vue";
import {saveLogger} from './assets/utils/request'
import {isKfcApp } from "@/utils/common";

window.initMaps = () => {console.log('谷歌地图js加载')}
export default {
  data() {
    return {
      H5Info: null,
      pageParam: null,
      routerShow:true,
      userId:'',
      showYuuLogin:false,
      language:language,
      userTitle:true,
      hashtag:true,
      showMaptip:false,
      navigatorGeolocation:{ },
      isSelectPlace:false,
      NOCURRENPLACE:'',
      NOCURRENPLACENEWTEXT:'',
      getFrontConfig:{},
      isPCImg:false,
      isPCImgUrlLeft:'',
      isPCImgUrlRight:'',
      loading:true
    };
  },
  methods: {
    $getFrontConfig (){
      let getFrontConfigData = localStorage.getItem("getFrontConfig")
      if(getFrontConfigData){
        this.getFrontConfig = JSON.parse(getFrontConfigData)
        if(this.getFrontConfig?.gaKey){
          this.addGaKey(this.getFrontConfig?.gaKey)
        }else{
          // 当接口未获取到时，用域名判断，以防止GA没有加载报错
          let gaKey = 'GTM-W37MGQD';
          const href = window.location.hostname;
          if(href.indexOf('uat')>-1||href.indexOf('127.0.0.1')>-1||href.indexOf('localhost')>-1) {
            gaKey = 'GTM-W37MGQD';
          } else if(href.indexOf('www.kfc.com.hk')>-1
            ||href.indexOf('ordering.kfchk.com')>-1
            ||href.indexOf('ab-v6-h5-kfc.can-dao.com.hk')>-1
            ||href.indexOf('ab-v6-h5-kfc-app.can-dao.com.hk')>-1){
            gaKey = 'GTM-KZVNNW5'
          }
          this.addGaKey(gaKey)
        }
      }
    },
  printFELogger(content){
    setTimeout(()=>{
      let paramssPrints = {
        actionName: "candao.base.printFELogger",
        content: {
          logLevel:'info',
          content:content
        },
      };
      if(this.$store.state.userInfo){
        let userInfo = {
          phoneNo:this.$store.state.userInfo.phoneNo||'',
          userId:this.$store.state.userInfo.userId||''
        }
        paramssPrints.content.content = paramssPrints.content.content+'-userInfo:' + JSON.stringify(userInfo);
      }
      postDC("Action", paramssPrints);
    },500)

  },
  getCurrentPosition (pageName,toName) {
    let self = this;
     let hashCurren = window.location.pathname;
    // let uA = navigator.userAgent;
    // yuu与app不进入
    // ||(this.$store.state.isYuu&&hashCurren.indexOf('/home')<0||hashCurren.indexOf('/selectShop')<0)
    // uA.indexOf('KFC-APP')>-1
    if(this.$store.state.isYuu||isKfcApp()){
      return false;
    }
    if(hashCurren.indexOf('/selectShop')<0&&hashCurren.indexOf('/home')<0){
      return false
    }
    if(hashCurren.indexOf('/home')>0){
      self.$store.commit("savePlace", null);
      let failLocaStart = self.$store.state.language == 1 ? "正在定位" : "Locating"
      self.$store.commit("saveLocationName",failLocaStart);
    }

    let clearLocaltion = true;
    if(navigator.geolocation){
        self.$store.commit('saveGeolocationStatus', -1)

        navigator.geolocation.getCurrentPosition(function(position){
          localStorage.setItem("KFClocationTipHide",'')
          console.log(position,'当前位置经纬度')

          let coords;
           if(position?.coords?.latitude){
              coords = {
                lat:position?.coords?.latitude||'',
                lng:position?.coords?.longitude||''
              }
              // 测试数据
              // coords = {
              //   lat:22.3192,
              //   lng:114.1693
              // }
              // if(coords.lat&&coords.lng){
              //   self.$store.commit("saveCurrenPosition", coords);
              // }
            }

          // self.$store.commit('saveGeolocationStatus', 1)

          if(pageName!=='SelectShop'){
            self.getLocation(['Home'].includes(toName) || !pageName,coords);
          }
          //已获取到定位
        },function(err){
          if(toName!=='SelectShop'){
            let isNotOpenGps = err.message.indexOf('denied')>-1;//是否设置未开gps
            self.$store.commit( "saveCurrenPosition", {isNotOpenGps});
          }
          console.log('未获取到当前定位',err)
          let failLoca = self.getNoplaceText();
          if(!self.$store.state?.userInfo){
            const routeName = self.$route.name
            // 首页才重置位置
            if (['Home'].includes(routeName)) {
              self.$store.commit("saveLocation", {});
              self.$store.commit("saveLocationName", failLoca);
              self.$store.commit("savePlace", null)
            }
          }else{
            // 无定位，如果没有选择默认地址，则选中默认地址
            if(toName!=='SelectShop'){
              if(self.$store.state.addressLits&&self.$store.state.addressLits.length){
                if(!self.$store.state.placeInfo||(self.$store.state.placeInfo&&!self.$store.state.placeInfo.isDefault)){
                  if(self.$store.state.addressLits[0].isDefault){
                    self.$store.commit("savePlace", self.$store.state.addressLits[0])
                  }else{
                    self.$store.commit("saveLocationName", failLoca);
                    self.$store.commit("savePlace", null)
                  }
                }
              }else{
                self.$store.commit("saveLocationName", failLoca);
                self.$store.commit("savePlace", null)
              }
            }
          }

          self.$store.commit('saveGeolocationStatus', 2)
          // alert('禁止'+localStorage.getItem("KFClocationTipHide"))
          clearLocaltion = false;
          if(err&&err.message){
            if(err.message.indexOf('denied')>-1){
              if(!localStorage.getItem("KFClocationTipHide")){
               self.showMaptip = true;
              }
            }
          }
        },{
          // 指示浏览器获取高精度的位置，默认为false
          enableHighAcuracy: true,
          // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
          timeout: 180000,
          // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
          maximumAge: 0
        });
        setTimeout(()=>{
          if(clearLocaltion){
            localStorage.setItem("KFClocationTipHide",'')
          }
        },1000)

    }else{
      console.log("Geolocation is not supported by this browser.");
    }
  },
	backPage(){
		this.showYuuLogin = false
	},
  closeTip () {
    this.showMaptip = false;
    localStorage.setItem("KFClocationTipHide", 1);

  },
  // 当获取yuu登录状态后，再获取用户信息拿yuuID更新
  async getUserDatayYuuLogin(isGetUserData){
    var paramsUser = {
          actionName: "candao.member.userProfileGet",
          content: {},
        };
        if(isGetUserData){
          let resultUser = await postPaas("UserUnify", paramsUser);
          if(resultUser.data){
            this.$store.commit("setYuuyuuId", resultUser.data);
          }
        }

  },
  // 判断yuu是否登录
  async getIsLoginYuu(successPost,isGetUserData=false,isHideLoading){
    // yuu环境无需调判断接口，直接认为是已登录
    if(this.$store.state.isYuu){
        successPost&&successPost(1);
        return 1;
    }
    try{
      // &&this.$store.state.userInfo.yuuId
      if(this.$store.state.userInfo&&this.$store.state.userInfo.userId){
        let result = await postPaas("UserUnify",{
            actionName: "candao.member.yuuLoginStatus",
            content:{
              userId:this.$store.state.userInfo.userId,
              isHideLoading
            }
        });
        if(result.status==1&&result?.data?.yuuToken){
          this.$store.commit("setYuuyuuToken",result.data.yuuToken);
          var paramsUser = {
            actionName: "candao.member.userProfileGet",
            content: {
              isHideLoading:true
            },
          };
          console.log(new Date().getTime(),'yuu时间-获取登录状态')
          successPost&&successPost(result.status)
          let resultUser;
          try{
            resultUser = await  postPaas("UserUnify", paramsUser)
          }catch(err){
            resultUser = null;
          }
          if(resultUser&&resultUser.data){
            this.$store.commit("setYuuyuuId", resultUser.data);
          }
          return result.status;
        }else{
          this.$store.commit("setYuuyuuToken",'');
          this.getUserDatayYuuLogin(isGetUserData)
        }
        successPost&&successPost(result.status)
        return result.status;
      }else{
        successPost&&successPost(2);
        this.$store.commit("setYuuyuuToken",'');
        this.getUserDatayYuuLogin(isGetUserData)

        return 2;

      }
    }catch(err){
      successPost&&successPost(2)
      this.$store.commit("setYuuyuuToken",'');
      this.getUserDatayYuuLogin(isGetUserData)
      return 2;
    }
  },
  testLogger () {
    console.log('App.vue testLogger')
    try {
      const href = window.location.href
      if (href.indexOf('cancelReason') < 0) {
        return
      }
      // const KFCOrderParam = localStorage.getItem("KFCOrderParam") || null
      // const KFCAppParameter = localStorage.getItem("KFCAppParameter") || null
      // const store = localStorage.getItem("store") || null
      const _body = {
        sign: 'App.vue localStorage info',
        // KFCOrderParam,
        // KFCAppParameter,
        // store
      }
      saveLogger(_body, 'info')
    } catch (err) {
      console.error(err)
    }
  },
	async getIsLogin(successPost){
    // notServer = true 处理app进入餐单页，先执行餐单页自己的app登录流程
    try {
      // if(this.$store.state.isYuu){
      //   if(!this.$store.state.yuuInfo||!this.$store.state.yuuInfo.cardNo){
      //     this.loginYuu()
      //   }
      // }
      let visterParams = {
        actionName: "isLogin",
        key: "40a443f1ced7f597",
        content: {
          deviceId: "iPhone",
        },
      }
      let visterRes = await postLogin("LocalAction",visterParams);

      
      if (visterRes && visterRes.status != 1) {
        if(this.$store.state.isYuu){
          this.loginYuu()
          successPost(visterRes)
          return
        }
        let isUser = this.$store.state.userInfo||this.userid;
        if(isKfcApp()){
          isUser = !!this.$store.state?.appInfo?.userId
        }
        if(isUser){
          // 餐单页当app进来时，只有userid，无userInfo时，先执行餐单页自己的执行方法登录
          // if(this.userid&&this.$store.state?.appInfo?.userId&&notServer){
          //   successPost(visterRes)
          //   return false;
          // }
          var userInfo = this.$store.state.userInfo || {}
          let paramsOne = {
            actionName: "candao.user.getUserByExtUserId",
            key: "40a443f1ced7f597",
            content: {
              extUserId: userInfo.userId||this.$store.state?.appInfo?.userId,
              phone: userInfo.phoneNo||this.$store.state?.appInfo?.phone,
            },
          };
          // 获取secretKey
          let canDaoUser = await postDC("Action", paramsOne);
          if (canDaoUser && canDaoUser.status != 1) {
            if (!this.$store.state.isYuu) {
              this.$store.state.loginGet = 1
              localStorage.removeItem("orderMap");
              localStorage.removeItem("KFCAdvert");
              localStorage.removeItem("store");
              this.$store.commit("reloadVuex");
              this.$router.push({path:"/login",query:{page:3}})
              return this.$toast(this.DLSB);
            }else{
              this.showYuuLogin = true
            }
          }

          var paramsTwo = {
            actionName: "candao.member.bindAccessCode",
            key: "40a443f1ced7f597",
            secretKey: canDaoUser.data.secretKey,
            content: {
              accessCode: userInfo.accessCode|| this.$store.state?.appInfo?.accessCode||this.$store.state?.appInfo?.accecode||'',
              deviceId: "iPhone",
            },
          };
          let bindCode = await postPaas("UserUnify", paramsTwo);
          if (bindCode && bindCode.status == 1) {
            this.$store.state.loginGet = 1
            // app进来时，放在获取用户信息后再回调
            if(!isKfcApp()){
              successPost(visterRes)
            }
          }else{
            this.$router.push({path:"/login",query:{page:3}})
          }

           // 如果是app,则需要获取用户信息
          if(isKfcApp()){
             // 获取用户信息
            var params = {
              actionName: "candao.member.userProfileGet",
              content: {},
            };
            // if (this.$store.state.userInfo) {
            let result = await postPaas("UserUnify", params);
            if(result.status==1){
              result.data.accessCode = this.$store.state?.appInfo?.accessCode;
              this.$store.commit("setUserInfo", result.data);
              successPost(visterRes)
            }
          
          }

        }else{
          let visterParams = {
            actionName: "candao.user.getOrCreateVisitor",
            content: {
              phone: 11111111
            }
          }
          let visterRes = await postDC("Action",visterParams);
          if (visterRes && visterRes.status != 1) {
            return this.$toast(this.HQXXSB);
          }
          successPost(visterRes)
        }
      }else{

        const {isYuu, yuuInfo} = this.$store.state
        if(isYuu){
          if(!yuuInfo || !yuuInfo.cardNo){
            this.loginYuu()
          }
        }

        successPost(visterRes)
      }
    } catch (err){
      console.log(err)
    }
    try {
      const isDev = process?.env?.NODE_ENV === 'development'
      if (!isDev) {
        saveLogger({
          urlParams: window.location.href
        })
      }
    } catch (err) {
      console.error(err)
    }
    if (this.userTitle) {
      this.getTitle();
    }
    if (this.hashtag) {
      this.getHashtag();
    }
  },
    async getH5Info() {
      var params = {
        actionName: "candao.storeOwn.getH5Setting",
        content: {},
      };
      let result = await postDC("Action", params);
      if (result && result.status == 1) {
        this.$store.commit("saveH5Seting", result.data);
      }
    },
    async getThemeGet(){
       var params = {
        actionName: "candao.member.themeGet",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result?.status == 1) {
        this.$store.commit("setThemeGetConfig",result?.data?.data)
      }
      setTimeout(()=>{
        this.loading = false
      })
    },
    getFrontConfigParams(){
      let getFrontConfigparams = {
        actionName: " candao.base.getFrontConfig",
        content: {
          platformKey:'a1b9f2aca1150632',
        },
      };
      localStorage.setItem("getFrontConfig",null)
      postPaas("Action", getFrontConfigparams).then(result=>{
        localStorage.setItem("getFrontConfig",result?.data?.configs?JSON.stringify(result?.data?.configs):null)
      });
    },
    doubleClick() {
      console.log(1);
    },
    leftEvent() {
      this.$store.commit("chageSetNotice", false);
    },
    async rightEvent() {
      var userInfo = this.$store.state.userInfo;
      // 修改用户信息
      let params = {
        actionName: "candao.member.userUpdate",
        content: {
          genderId: userInfo.genderId,
          birthday: {
            year: userInfo.birthday.month,
            month: userInfo.birthday.month,
            day: userInfo.birthday.day,
          },
          firstName: userInfo.firstName || userInfo.lastName || 'Member',
          receiveNews: true,
          addressNotification: userInfo.addressNotification || false
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result && result.status == 1) {
        var payload = {
          receiveNews: true,
        };
        this.$store.commit("updateUserInfo", payload);
        this.$store.commit("chageSetNotice", false);
      }
    },
    isPhone(){
        let sUserAgent = navigator.userAgent.toLowerCase();
        let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        let bIsMidp = sUserAgent.match(/midp/i) == "midp";
        let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        let bIsAndroid = sUserAgent.match(/android/i) == "android";
        let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
          return true;
        } else {
          return false;
        }
    },
    async getLocation(isUsenNewLocation = true,currenPosition) {
      let hashCurren = this.$route.name;
      let hashCurren1 = window.location.pathname
      let isUsew3c = true;
      if(isUsew3c
        &&(hashCurren == 'SelectShop' || hashCurren == 'Home'||hashCurren1.indexOf('/home')>-1||hashCurren1.indexOf('/selectShop')>-1)){
        await this.getLocationCurrent(isUsenNewLocation,currenPosition);
        return false;
      }
      if(isUsew3c){
        return false;
      }
      let language = this.$store.state.language == 1 ? 'zh-HK' : 'en'
      const url =
        "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAYEA33OSsk8TyyIZJjPgLhyTVCjlAUk5k&libraries=places&language=" + language;
      let result = await postPlace(url, {});
      if (result && result.status != 200) {
        return;
      }
      const lat = result.data.location.lat;
      const lng = result.data.location.lng;
      const investigationUrl =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyAYEA33OSsk8TyyIZJjPgLhyTVCjlAUk5k&language=" + language;
      let placeResult = await getPlace(investigationUrl, {});

      // 【20230425】添加谷歌定位日志
      this.setLocationLog(investigationUrl, placeResult)

      if (placeResult.status == 200) {
        this.$store.commit(
          "saveLocation",
          placeResult.data.results[0].geometry.location
        );
        var failLoca = this.getNoplaceText() ;
        this.$store.commit(
          "saveLocationName",
          placeResult.data.results[0].formatted_address || failLoca
        );
        let hashCurren = window.location.pathname;
        let isgetNewData = (this.$store.state.placeInfo
                            &&!this.$store.state.placeInfo?.addressCode
                            &&isUsenNewLocation
                            &&(hashCurren.indexOf('/selectShop')>-1||hashCurren.indexOf('/home')>-1)
                          );
        if (!this.$store.state.placeInfo||isgetNewData) {
          var copyInfo = {
            building: placeResult.data.results[0].formatted_address,
            district: placeResult.data.results[0].formatted_address,
            lat: this.navigatorGeolocation.lat|| placeResult.data.results[0].geometry.location.lat,
            lng: this.navigatorGeolocation.lng||placeResult.data.results[0].geometry.location.lng,
          };

          this.$store.commit("savePlace", copyInfo);
          this.$store.commit("saveUserPlace", [copyInfo.lat, copyInfo.lng]);
        }
      } else {
        return;
      }
    },
    async getLocationCurrent(isUsenNewLocation,currenPosition) {
      if(this.isSelectPlace){
        return false;
      }
      var failLocaStart = this.$store.state.language == 1 ? "正在定位" : "Locating"
      // 获取到当前位置
      if(currenPosition&&currenPosition.lat){
        await this.getCurrengetPlaceKfc(currenPosition, isUsenNewLocation);
        setTimeout(()=>{
          this.printFELogger(JSON.stringify(currenPosition)+'当前定位信息4=')
        })

        return false;
      }
      this.$store.commit("saveLocationName",failLocaStart);
      let self = this;
      if(currenPosition&&currenPosition.lat){
        self.getCurrengetPlace(currenPosition,isUsenNewLocation)
      }
       if(navigator.geolocation){
          navigator.geolocation.getCurrentPosition(
            (position) => {
              //  let aaa = {...position.coords}
              //   var paramssPrints = {
              //   actionName: "candao.base.printFELogger",
              //   content: {
              //     logLevel:'info',
              //     content:JSON.stringify(aaa)+'当前定位信息4='
              //   },
              // };
              // postDC("Action", paramssPrints);
              // this.printFELogger(JSON.stringify(aaa)+'当前定位信息4=')
              if(position?.coords?.latitude){
                let coords = {
                  lat:position?.coords?.latitude||'',
                  lng:position?.coords?.longitude||''
                }
                if(coords.lat&&coords.lng){
                  self.getCurrengetPlaceKfc(coords,isUsenNewLocation)
                }
              }
            },
            (err)=>{
              console.log(err,'未获取到当前定位')
               let failLoca = self.getNoplaceText();

               if(!self.$store.state?.userInfo){
                this.$store.commit(
                    "saveLocation",
                  {}
                  );
                 this.$store.commit(
                    "saveLocationName",
                    failLoca
                  );
                  this.$store.commit("savePlace", null)
               }else{
                // 无定位，如果没有选择默认地址，则选中默认地址
                // this.$store.state.placeInfo&&this.$store.state.placeInfo.isDefault
                if(this.$store.state.addressLits&&this.$store.state.addressLits.length){
                  if(!this.$store.state.placeInfo||(this.$store.state.placeInfo&&!this.$store.state.placeInfo.isDefault)){
                    if(this.$store.state.addressLits[0].isDefault){
                      this.$store.commit("savePlace", this.$store.state.addressLits[0])
                    }else{
                      this.$store.commit(
                        "saveLocationName",
                        failLoca
                      );
                      this.$store.commit("savePlace", null)
                    }
                  }
                }
                // state.addressLits

               }
              this.printFELogger('message:'+JSON.stringify(err.message||'')+'code:'+JSON.stringify(err.code)+'获取当前定位2=')
            },
            {
              // 指示浏览器获取高精度的位置，默认为false
              enableHighAcuracy: true,
              // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
              timeout: 180000,
              // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
              maximumAge: 0
            }
          )
        }else{
          console.log('Geolocation is not supported by this browser')
          // var paramssPrint = {
          //   actionName: "candao.base.printFELogger",
          //   content: {
          //     logLevel:'info',
          //     content:'Geolocation is not supported by this browser'+'获取当前定位3='
          //   },
          // };
          // postDC("Action", paramssPrint);
          this.printFELogger('Geolocation is not supported by this browser'+'获取当前定位3=')
        }
    },
    async getCurrengetPlace(data,isUsenNewLocation = true){
        console.log(this.$store.state?.placeInfo,data,'定位信息')
        let self = this;
        if ((this.$store.state?.placeInfo
          &&data.lat==this.$store.state.placeInfo.lat
          &&data.lng==this.$store.state.placeInfo.lng)
          ||this.$store.state.placeInfo?.addressCode) {
            return false;
        }
        let language = this.$store.state.language == 1 ? 'zh-HK' : 'en'
        const lat = data.lat;
        const lng = data.lng;
        const investigationUrl =
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          lat +
          "," +
          lng +
          "&key=AIzaSyAYEA33OSsk8TyyIZJjPgLhyTVCjlAUk5k&language=" + language;
        let placeResult = await getPlace(investigationUrl, {});

        // 【20230425】添加谷歌定位日志
        this.setLocationLog(investigationUrl, placeResult)

        if (placeResult && placeResult.status == 200) {
          if(this.isSelectPlace){
            return false;
          }
          this.$store.commit(
            "saveLocation",
            placeResult.data.results[0].geometry.location
          );
          var failLoca = this.getNoplaceText();
          this.$store.commit(
            "saveLocationName",
            placeResult.data.results[0].formatted_address || failLoca
          );
          let hashCurren = window.location.pathname;
          let isgetNewData = (this.$store.state.placeInfo
                              &&!this.$store.state.placeInfo?.addressCode
                              &&isUsenNewLocation
                              &&(hashCurren.indexOf('/selectShop')>-1||hashCurren.indexOf('/home')>-1)
                            );
          if (!self.$store.state.placeInfo||isgetNewData) {
            var copyInfo = {
              building: placeResult.data.results[0].formatted_address,
              district: placeResult.data.results[0].formatted_address,
              lat:lat||placeResult.data.results[0].geometry.location.lat,
              lng:lng||placeResult.data.results[0].geometry.location.lng,
            };

            self.$store.commit("savePlace", copyInfo);
            self.$store.commit("saveUserPlace", [copyInfo.lat, copyInfo.lng]);
            // let contentData ={
            //   lat:lat,
            //   lng:lng,
            //   lat1:copyInfo.lat,
            //   lng1:copyInfo.lng
            // }
            //  var paramssPrint = {
            //     actionName: "candao.base.printFELogger",
            //     content: {
            //       logLevel:'info',
            //       content:JSON.stringify(contentData)+'获取当前定位后'
            //     },
            //   };
            //   postDC("Action", paramssPrint);
            //   console.log(JSON.stringify(contentData),'888')
              // this.printFELogger(JSON.stringify(contentData)+'获取当前定位后')
          }
        } else {
          return;
        }
    },
    // 传经纬度获取azure接口地址详情
    async getCurrengetPlaceKfc(data,isUsenNewLocation = true){
      //  if(this.$store.state?.placeInfo?.addressCode){
      //     return false;
      //   }
      let self  =  this;
      const lat = data.lat;
      const lng = data.lng;
      const params = {
        actionName: "candao.member.addressSearch",
        content: {lat, lng}
      }
      let placeResultSearch = null
      try{
        placeResultSearch = await postPaas("UserUnify", params);
      }catch(err){
        console.error(err)
      }

      const placeResult = placeResultSearch?.data?.data || []
      console.log('placeResult: ', placeResult)
      let address,location,formatted_address;
      if(placeResult.length){
        formatted_address = placeResult[0];
        address = formatted_address.fullAddress||'';
        location = {
          lat:formatted_address.lat||'',
          lng:formatted_address.lng||''
        }
      }
      const {language} = this.$store.state
      this.$store.commit("saveLocation", location)
      const failLoca = parseInt(language, 10) === 1 ? "未能定位你的所在位置" : "Unable to locate your location";
      this.$store.commit("saveLocationName", address || failLoca);
      const hashCurren = window.location.pathname;
      let isgetNewData = (isUsenNewLocation &&(hashCurren.indexOf('/selectShop')>-1||hashCurren.indexOf('/home')>-1));
      console.log(this.$store.state.placeInfo, isgetNewData)
      if (formatted_address&&(!this.$store.state.placeInfo||isgetNewData)) {
        const copyInfo = {
          building:formatted_address.building,
          district: formatted_address.district,
          lat:formatted_address.lat,
          lng:formatted_address.lng,
          fullAddress:formatted_address.fullAddress
        };
        this.$store.commit("savePlace", copyInfo);
        this.$store.commit("saveUserPlace", [copyInfo.lat, copyInfo.lng]);
        this.$store.commit("saveCurrenPosition", copyInfo);

        self.$store.commit('saveGeolocationStatus', 1)

        const {addressLits = [], placeInfo} = this.$store.state
        if(addressLits.length){
          if(!placeInfo || (placeInfo && !placeInfo.isDefault)){
            if(addressLits[0].isDefault){
              this.$store.commit("savePlace", addressLits[0])
            }
          }
        }
      }
      // 有经纬度，但是没有获取到地址详情
      if(!placeResult.length){
        console.log('有获取到当前位置经纬度，未获取到地址详情')
        if (isgetNewData) {
          this.$store.commit("saveLocationName", this.$store.state.language == 1 ?'未能定位你的所在位置':'Unable to locate your location');
          this.$store.commit("savePlace", null);
          this.$store.commit("saveUserPlace", null);

          const {addressLits = [], placeInfo} = this.$store.state
          if(addressLits.length){
            if(!placeInfo || (!placeInfo?.isDefault)){
              if(addressLits[0].isDefault){
                self.$store.commit("savePlace", addressLits[0])
              }
            }
          }
        }

        // 如azure 没有返回地址，则当作定位失败
        self.$store.commit("saveCurrenPosition", {});
        self.$store.commit('saveGeolocationStatus', 2)

        const logData ={
          result:placeResultSearch,
          params:params.content,
          tips: '根据当前定位获取地址详情结果 - 无数据'
        }
        this.printFELogger(JSON.stringify(logData))
      } else {
        self.$store.commit('saveGeolocationStatus', 1)
      }
    },
    setLocationLog (url, result) {
      try {
        const params = {
          sign: 'maps.googleapis.com',
          url
        }
        if (result.status === 200) {
          params.result = result.data
        } else {
          params.result = {
            status: result.status,
            statusText: result.statusText
          }
        }

        saveLogger(params)
      } catch (err) {
        console.error(err)
      }
    },
    async loginYuu(idtoken) {
      let params = {
        actionName: "candao.member.yuuProfileGet",
        content: {
          yuuToken: idtoken||this.pageParam?.idtoken,
        },
      };
      let yuuUserInfo;

      const yuuInfo = this.$store.state.yuuInfo || {}
      if (yuuInfo?.yuuGuestKey && params.content.yuuToken && yuuInfo?.idtoken && yuuInfo?.idtoken === params.content.yuuToken ) {
        // 經溝通 yuuGuestKey 不會失效
        // idtoken 不變的情況下，如緩存中存在yuuGuestKey，則使用緩存中的信息
        yuuUserInfo = {
          status: 1,
          data: {
            ...yuuInfo
          }
        }
      } else {
        try{
          yuuUserInfo = await postPaas("UserUnify", params);
        }catch(err){
          yuuUserInfo = err;
          if(err.msg&&err.msg.indexOf('403')>-1){
            if(this.$store.state.yuuInfo){
              let  yuuInfo = JSON.parse(JSON.stringify(this.$store.state.yuuInfo))
              yuuInfo.isYuuGuestKeyLose = true;//yuu YuuGuestKey是否失效
              this.$store.commit("saveYuuInfo", yuuInfo);
            }

          }
        }
      }
      if (yuuUserInfo&&yuuUserInfo.status == 1) {
        if (
          !yuuUserInfo.data ||
          !yuuUserInfo.data.memberProfileList ||
          !yuuUserInfo.data.memberProfileList.length
        ) {
          return;
        }
        var memberProfileList = yuuUserInfo.data.memberProfileList[0];
        var hasAddress = false;
        var failLoca = this.getNoplaceText();
        memberProfileList.addresses.forEach((val) => {
          if (val.type == this.pageParam.addresstype) {
            hasAddress = true;
            if (!val.postalCode) {
              this.$store.commit("saveLocationName", failLoca);
            } else if (typeof val.postalCode === 'string') {
              let postalCode = {
                postalCodeOld: val.postalCode,
              };
              let placeInfo = val.postalCode.split(",");
              placeInfo.forEach((val) => {
                let infoItem = val.split(":");
                if (infoItem[0].indexOf("lat") > -1) {
                  postalCode.lat = Number(infoItem[1].split("'").join(""));
                }
                if (infoItem[0].indexOf("lng") > -1) {
                  postalCode.lng = Number(infoItem[1].split("'").join(""));
                }
              });
              var copyInfo = {
                building: val.address2,
                district: val.region,
                lat: postalCode.lat,
                lng: postalCode.lng,
              };
              this.$store.commit("savePlace", copyInfo);
              val.postalCode = postalCode;
              if (val.postalCode.lat && val.postalCode.lng) {
                yuuUserInfo.data.addressNow = val;
              }
            }
            return;
          }
        });
        if (!hasAddress) {
          if (
            memberProfileList.addresses &&
            memberProfileList.addresses.length > 0
          ) {
            if (
              memberProfileList.addresses[0].postalCode &&
              memberProfileList.addresses[0].postalCode.lat &&
              memberProfileList.addresses[0].postalCode.lng
            ) {
              yuuUserInfo.data.addressNow = memberProfileList.addresses[0];
            } else {
              this.$store.commit("saveLocationName", failLoca);
            }
          }
        }
        yuuUserInfo.data.idtoken = params.content.yuuToken
        this.$store.commit("saveYuuInfo", yuuUserInfo.data);
        this.getUserByExtUserId(yuuUserInfo.data);
      } else {
        if(yuuUserInfo&&yuuUserInfo.msg&&yuuUserInfo.msg.indexOf('403')>-1){
          if(this.$store.state.yuuInfo){
            let  yuuInfo = JSON.parse(JSON.stringify(this.$store.state.yuuInfo))
            yuuInfo.isYuuGuestKeyLose = true;//yuu YuuGuestKey是否失效
            this.$store.commit("saveYuuInfo", yuuInfo);
          }
        }
        return;
      }
    },
    async getUserByExtUserId(yuuInfo) {
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: yuuInfo.cardNo,
          phone: yuuInfo.memberProfileList[0].phoneNo.slice(4),
        },
      };
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser && canDaoUser.status == 1) {
        this.$store.commit("saveYuuLoginInfo", canDaoUser.data);
      }
    },
    // 自动登录
    async loginAutomatic() {
      var userInfo = this.$store.state.userInfo || {}
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: userInfo.userId||this.$store.state?.appInfo?.userId,
          phone: userInfo.phoneNo||this.$store.state?.appInfo?.phone,
        },
      };
      // 获取secretKey
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status != 1) {
        this.$toast(this.HQXXSB);
        return false;
      }
      var paramsTwo = {
        actionName: "candao.member.bindAccessCode",
        secretKey: canDaoUser.data.secretKey,
        content: {
          accessCode: userInfo.accessCode|| this.$store.state?.appInfo?.accessCode||this.$store.state?.appInfo?.accecode||'',
          deviceId: "iPhone",
        },
      };
      // 绑定accessCode
      let bindCode = await postPaas("UserUnify", paramsTwo);
      if (bindCode.status == 1) {
        window.dataLayer.push({
          event: "login",
        });
      } else {
        this.$toast(this.HQXXSB);
        return false;
      }
      var params = {
        actionName: "candao.member.userProfileGet",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$store.commit("setUserInfo", result.data);
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    async getTitle() {
      let params = {
        actionName: "candao.member.miscUserTitles",
        content: {},
      };

      let placeParams = {
        actionName: "candao.member.miscAddressTag",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
        placeParams.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result && result.status == 1) {
        // result.data.data.splice(result.data.data.length - 1, 1);
        this.userTitle = false
        let list = result.data.data;
         list.forEach(m=>{
          if(m.titlesCode=='U04'){
            m.titlesName = params.content.language=='TC'?'非公開':'Prefer not to say'
          }
         })
        this.$store.commit("saveUserTitle", list);
      }
      let placeResult = await postPaas("UserUnify", placeParams);
      if (placeResult && placeResult.status == 1) {
        this.$store.commit("savePlaceTag", placeResult.data.data);
      }
    },
    setLanguage() {
      this.lanType = this.$store.state.language;
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.NOCURRENPLACE = language.NOCURRENPLACE[type];
      this.NOCURRENPLACENEWTEXT = language.NOCURRENPLACENEWTEXT[type];

    },
    async getHashtag() {
      let params = {
        actionName: "candao.member.miscHashTag",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result?.state == 1) {
        this.hashtag = false
        this.$store.commit("saveHashtag", result.data.data);
      }
    },
    getNoplaceText(){
      let lanType = this.$store.state.language;
      let  type = "chinese";
      if (lanType != 1) {
        type = "english";
      }
      return language.NOCURRENPLACENEWTEXT[type]
    },
    ...mapMutations(["saveYuu", "clearUserInfo"]),
    // 添加ga配置
    addGaKey(gaKey){
      let w=window, d=document,  s='script', l='dataLayer', i=gaKey;
      // (function(w, d, s, l, i){
        w[l] = w[l] || []; w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      // })(window, document, 'script', 'dataLayer', gaKey);
      var googlegaIframe = document.getElementById("googlegaIframe");
      // var noscript = document.createElement("noscript");
      // noscript.innerHTML=`<iframe src="https://www.googletagmanager.com/ns.html?id=${gaKey}" height="0" width="0"
      //   style="display:none;visibility:hidden;color:#666"></iframe>`
      // document.body.appendChild(noscript)
      if(googlegaIframe){
        googlegaIframe.innerHTML='';
        var iframeDom = document.createElement("iframe");
        iframeDom.src = 'https://www.googletagmanager.com/ns.html?id='+gaKey
        iframeDom.style = 'display:none;visibility:hidden;color:#777'
        googlegaIframe.appendChild(iframeDom)
      }
    }
  },
  mounted(){
    // this.getFrontConfigParams()
    this.getThemeGet()
    this.$store.state.orderMsgShow = false
  },
   created() {
    this.$getFrontConfig();
    // this.getCurrentPosition()
    // if (!window.location.href.startsWith("https")) {
    //   var locahref = window.location.href;
    //   locahref=locahref.replace("http", "https");
    //   window.location.href = locahref;
    //   return;
    // }

    this.$store.commit("resetGeolocationStatus")

    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    let u = navigator.userAgent;
    let isPC = true;
    if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
      isPC = false;
    } else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
      isPC = false;
    }
    this.isPCImg = isPC
    this.$store.commit("saveIsPC", isPC);
    // 【时区】 初始化h5时，无法获取到服务器时间，故这里不调整
    var nowTime = new Date().getTime(); //当前时间
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    var day = new Date().getDate();
    var date = year + "/" + month + "/" + day + " ";
    var breakfast = new Date(date + " 10:59").getTime();
    var lunch = new Date(date + " 14:29").getTime();
    var tea = new Date(date + " 17:29").getTime();
    var saveTimeType;
    if (nowTime < breakfast) {
      saveTimeType = "H01";
    } else if (nowTime < lunch) {
      saveTimeType = "H02";
    } else if (nowTime < tea) {
      saveTimeType = "H03";
    } else {
      saveTimeType = "H04";
    }
    this.$store.commit("saveTime", saveTimeType);
    var KFCClear = localStorage.getItem("KFCClear");
    if (KFCClear) {
      localStorage.removeItem("KFCClear");
      this.$store.commit("clearCart");
    }
    this.saveYuu(false);
    var href = window.location.href;
    if (href.indexOf("idtoken") > -1) {
      if(href.indexOf("cancelReason") == -1){
        localStorage.setItem("yuuUrl", window.location.href);
      }
      href = href.split('?').filter(item => item.indexOf('idtoken') > -1)
      let idtoken;
      if (href.length > 0) {
        var param = {};

        if (href[0].indexOf('#') > -1) {
          href[0] = href[0].split("#")[0]
        }
        var hrefParam = href[0].split("&");
        hrefParam.forEach((val) => {
          var P = val.split("=");
          if (P[0] == "addresstype") {
            var typeTime = P[1].split("T");
            P[1] = typeTime[0] + "T" + decodeURIComponent(typeTime[1]);
          }
          param[P[0]] = P[1];
          if (P[0] == "idtoken") {
            idtoken = P[1];
            this.saveYuu(true);
            this.clearUserInfo();
          }
        });
        this.pageParam = param;
        if (this.$store.state.isYuu||idtoken) {
          let LANG = this.pageParam.lang == "en" ? 2 : 1;
          this.$store.commit("setLanguage", LANG);
          this.getIsLogin(()=>{
            this.loginYuu(idtoken);
          })
        }
      }
    }
    let yuuParam = this.pageParam || {};
    yuuParam.isYuu = this.$store.state.isYuu;
	if(yuuParam.DisplayOrderNo){
		delete yuuParam.DisplayOrderNo
	}
	if(yuuParam.AuthCode){
		delete yuuParam.AuthCode
	}
	if(yuuParam.cancelReason){
		delete yuuParam.cancelReason
	}
    localStorage.setItem("yuuParam", JSON.stringify(yuuParam));
    // if (!this.$store.state.isYuu) {
    //   this.getLocation(true);
    // }
    this.setLanguage();

    var  brazeSafariWebsitePushId = "web.com.kfchk.uat ";
    // 正式环境
    var baseKey = 'b495d5a8-70ff-4163-9057-9c4f946901a4';
    if(href.indexOf('uat')>-1
     ||href.indexOf('127.0.0.1')>-1
     ||href.indexOf('localhost')>-1){
      baseKey = 'b495d5a8-70ff-4163-9057-9c4f946901a4'
      brazeSafariWebsitePushId = "web.com.kfchk.uat";
      this.isPCImgUrlLeft = "https://stkfchkmidprd001.blob.core.windows.net/webblog/OLOPCweb-bg-uat-leftside.png"
      this.isPCImgUrlRight = "https://stkfchkmidprd001.blob.core.windows.net/webblog/OLOPCweb-bg-uat-rightside.png"
    }else if (href.indexOf('www.kfc.com.hk')>-1
       ||href.indexOf('ordering.kfchk.com')>-1
       ||href.indexOf('ab-v6-h5-kfc.can-dao.com.hk')>-1
       ||href.indexOf('ab-v6-h5-kfc-app.can-dao.com.hk')>-1) {
      baseKey = 'ada30db2-056e-4136-9e4f-c346ba0b795a'
      brazeSafariWebsitePushId = "";
      this.isPCImgUrlLeft = "https://stkfchkmidprd001.blob.core.windows.net/webblog/OLOPCweb-bg-prd-leftside.png"
      this.isPCImgUrlRight = "https://stkfchkmidprd001.blob.core.windows.net/webblog/OLOPCweb-bg-prd-rightside.png"
    }
    if(this.getFrontConfig?.brazeKey){
      baseKey = this.getFrontConfig.brazeKey;
      brazeSafariWebsitePushId = this.getFrontConfig?.brazeSafariWebsitePushId||'';
    }
     braze.initialize(baseKey, {
      baseUrl: "sdk.iad-06.braze.com",
      safariWebsitePushId: brazeSafariWebsitePushId,
      enableLogging: true
    });
    if (braze.automaticallyShowInAppMessages) {
      braze.automaticallyShowInAppMessages();
    }
    // 检查浏览器是否⽀持⽹络推送。如果是，请求推送权限请求。
    // if (braze.isPushSupported()) {
    //   if(braze.requestPushPermission) {
    //     braze.requestPushPermission();
    //   }
    // }
    braze && braze.openSession();

    // 判断是否为kfc app进来
    if(isKfcApp()){
      this.$store.commit("saveApp", true);
    }else{
       var P = encodeURIComponent(localStorage.getItem("KFCOrderParam"));
      if(P&&P?.app){
        P.app =false;
        localStorage.setItem("KFCOrderParam", P);
      }
      this.$store.commit("saveApp", false);
    }
    let  userAgent = window.navigator.userAgent
    if(window.eruda&&
   ( userAgent.indexOf('V2020CA')>-1
    ||userAgent.indexOf('Redmi')>-1)){
      window.eruda.init();
    }
  },
  components: {
    Tabbar,
    SetNotice,
	FoodConfirm
  },
  // 监听,当路由发生变化的时候执行
  watch: {
	loginGet(){
		if(this.$store.state.loginGet == 2){
			if(this.$store.state.userInfo){
				if (!this.$store.state.isYuu) {
          this.$store.state.loginGet = 1
          localStorage.removeItem("orderMap");
          localStorage.removeItem("KFCAdvert");
          localStorage.removeItem("store");
          this.$store.commit("reloadVuex");
          this.$router.push({path:"/login",query:{page:3}})
        }
			}
		} else if (this.$store.state.loginGet == 3) {
      this.$store.state.loginGet = 1
      this.loginAutomatic()
    }
	},
    $route(to,form) {
      // console.log(to,'进入页面',form)
      //判断是否显示tabbar
		if(to.query._index){
			this.userid = window.atob(window.atob(to.query._index))
		} else {
      this.userid = ''
    }
      if (
        to.path == "/" ||
        to.name == "Home" ||
        to.name == "Coupon" ||
        to.path == "/orderList" ||
        to.path == "/mine" ||
        to.path == "/index.html" ||
        to.path == "/dist/index.html"
      ) {
        this.$store.commit("updateTabbarShow", true);
      } else {
        this.$store.commit("updateTabbarShow", false);
      }
      var tabType = 1;
      if (
        to.path == "/" ||
        to.name == "Home" ||
        to.path == "/index.html" ||
        to.path == "/dist/index.html"
      ) {
        tabType = 1;
      } else if (to.name == "Coupon") {
        tabType = 2;
      } else if (to.path == "/orderList") {
        tabType = 3;
      } else if (to.path == "/mine") {
        tabType = 4;
      }
      this.$store.commit("changeTabPage", tabType);
      // ||to.name=='SelectShop'
      // if(to.name=='Home'){
      //   this.getCurrentPosition(form.name)
      // }
      if(to.name=='Home'||to.name=='SelectShop'){
        // 处理当从地址页选完地址回来后，首页的获取当前定位时间比较久还未返回结果的情况，否则地址会被后面返回的当前定位结果覆盖
        if(form.name=='SelectShop'&&this.$store.state?.placeInfo){
          this.isSelectPlace = true;
        }else{
          this.isSelectPlace = false;
        }

        const _placeInfo = this.$store.state?.placeInfo || {}
        const needRefresh = _placeInfo?.needRefresh || false
        const formName = form.name || ''
        // 从地址页进来的不重新去获取当前经纬度
        // if(!['PersonInfo', 'Login'].includes(formName) && ('needRefresh' in _placeInfo && !needRefresh) && to.name === 'Home'){
        //   return false;
        // }
        // this.getCurrentPosition(form.name,to.name)

        const refreshLocation = () => {
          let  placeInfo = localStorage.getItem("KFCPlace");
          // 从下单后的订单详情页回来，缓存了地址回选回首页
          if(placeInfo){
            let placeInfo_ =JSON.parse(placeInfo);
            if(placeInfo_.returnPage=='orderDetail'){
              this.$store.commit("savePlace", placeInfo_);
              localStorage.removeItem("KFCPlace");
            }else{
              localStorage.removeItem("KFCPlace");
              refreshLocation()
            }
          }else{
            this.getCurrentPosition(form.name,to.name)
          }
        }
        // 从地址页进来的不重新去获取当前经纬度
        if (('needRefresh' in _placeInfo) && needRefresh) {
          console.log('needRefresh 重新获取定位')
          refreshLocation()
          return
        }
        if(form.name&&!['PersonInfo', 'Login'].includes(formName)&&to.name==='Home'){
          return false;
        }
        refreshLocation()
      }
    }
  },
  computed: {
	loginGet(){
		return this.$store.state.loginGet;
	},
    tabbarShow() {
      return this.$store.getters.getTabbarShow&&!isKfcApp();
    },
    leftBtn() {
      return this.$store.state.language == 1 ? "不允許" : "Don’t Allow";
    },
    rightBtn() {
      return this.$store.state.language == 1 ? "允許" : "Allow";
    },
    title() {
      return this.$store.state.language == 1
        ? "「KFC HK」想要傳送通知"
        : '"KFC Hong Kong" Would Like to Send You Notifications';
    },
    content() {
      return this.$store.state.language == 1
        ? "通知可包含提示、聲音和圖像標記。可以在「設定」中進行設定"
        : "Notifications may include alerts, sounds and icon badges. These can be configured in Settings";
    },
  }
};
</script>
<style lang="scss">
*:not(html) {
  margin: 0;
  padding: 0;
  max-width: 3.75rem;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

body {
  min-height: 100vh;
  background-color: #fff;
  width: 3.75rem;
  margin-left: calc(50% - 1.875rem) !important;
}
html {
  width: 100%;
  height: auto !important;
}
#app {
  font-family:PingFang HK,Roboto,PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	&.en{
		div,span,p{
			// font-family:Roboto !important
		}
	}
}
.isPCImg{
  position: fixed;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 50%;
  z-index: -1;
  width: calc(50% - 187px);
}
.isPCImgRight{
  left: auto;
  right: 0%;
}
@media screen and (max-width: 1024px) {
  .isPCImgLeft{
    display: none !important;
  }
  .isPCImgRight{
    display: none !important;
  }
}
input {
  background-color: #fff;
  color:#000 !important;
}
input:focus {
  background-color: #fff;
}
input[type="password"]::-ms-reveal {
  display: none;
}
p,div{
	word-break: break-word;
}
.van-cell__value {
  color: #03060d !important;
}
.popup div {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  text-align: center;
}

.van-overlay {
  left: 50% !important;
  transform: translateX(-50%);
}
.van-popup--bottom {
  left: 50% !important;
  transform: translateX(-50%);
}
.van-picker__confirm,.van-picker__cancel{
  font-size: .16rem !important;
}
.van-popup {
  transition: none !important;
  max-width: 3rem !important;
}

.van-picker__title.van-ellipsis{
  font-size: .17rem;
}

.van-swipe__indicator{
  width: .08rem !important;
  height: .08rem !important;
}

.router-link-exact-active {
  color: #42b983;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  text-decoration: none;
  color: #000;
}
.gmnoprint,
.gm-control-active.gm-fullscreen-control {
  // display: none !important;
}
.van-popup {
  z-index: 99999999999999999999 !important;
}
#app::-webkit-scrollbar {
  display: none;
}
.app::-webkit-scrollbar {
  display: none;
}
.bar {
  position: fixed;
  bottom: 0rem;
  z-index: 99999999999;
}
.van-popup.van-popup--center.van-toast.van-toast--middle.van-toast--loading {
  background-color: rgba(0, 0, 0, 0) !important;
}
.van-toast__loading {
  color: #000 !important;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: all 0.2s;
}
.fade-leave-active {
  opacity: 0;
  transition: all 0.2s;
}
.mescroll-downwarp.mescroll-downwarp-reset {
  height: 60px;
}
.mescroll-downwarp .downwarp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}
.titleColor {
  color: #474747;
}
.van-dialog__confirm, .van-dialog__confirm:active{
  color: #e4022b;
  font-size: .16rem;
}
.van-dialog__message{
  font-size: 16px;
  font-weight: 600;
}
.van-dialog__content--isolated{
  min-height: auto;
}
.van-dialog__cancel, .van-dialog__confirm{
  height: 0.54rem;
  line-height: 0.54rem;
  border-top: 1px solid #cacbcc;
}
.van-dialog{
  border-radius: .12rem;
}
.van-dialog .van-dialog__content:after{
  content: '';
  width: 1.56rem;
  display: block;
  background: url("./assets/icon/mainT.png") no-repeat center;
  background-size: 1.56rem 0.1rem;
  height: 0.1rem;
  margin-bottom: 0.2rem;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%,0);
}
body::-webkit-scrollbar {
  width: 0;
}
body{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
body::-ms-scrollbar {
  width: 0;
}
.markdown-body li{
  display: list-item;
  margin:0;
}
.markdown-body ul{
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0.2rem;
}
</style>
<style >
  .backgroundToastOpacity100{
    background: #292929;
    /* background: red; */
  }
</style>
